AFRAME.registerComponent('angle-between-markers', {
  schema: { 
    firstMarkerId: {type: 'string'},
    secondMarkerId: {type: 'string'}
  },
  
  init: function() {
    this.firstMarker = document.getElementById(this.data.firstMarkerId);
    this.secondMarker = document.getElementById(this.data.secondMarkerId);
    
    this.firstMarker.addEventListener('markerFound', () => { this.firstMarkerFound = true; });
    this.firstMarker.addEventListener('markerLost', () => { this.firstMarkerFound = false; });
    this.secondMarker.addEventListener('markerFound', () => { this.secondMarkerFound = true; });
    this.secondMarker.addEventListener('markerLost', () => { this.secondMarkerFound = false; });
  },

  tick: function() {
    if (!this.firstMarkerFound || !this.secondMarkerFound) {
      return;
    }

    let firstMarkerQuaternion = this.firstMarker.object3D.quaternion.clone();
    let secondMarkerQuaternion = this.secondMarker.object3D.quaternion.clone();

    let angle = firstMarkerQuaternion.angleTo(secondMarkerQuaternion) * 180 / Math.PI;
    this.el.emit('angle-found', { angle: angle })
  }
});